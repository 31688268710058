import React from 'react';
import { Link } from 'gatsby';
import DefaultLayout from '../layouts/DefaultLayout';

// markup
const NotFoundPage = () => {
  return (
    <DefaultLayout title="Not Found">
      <div class="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 my-auto py-16 sm:py-32">
          <p class="text-sm font-semibold text-emerald-400 uppercase tracking-wide">404</p>
          <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Not Found
          </h1>
          <p class="mt-2 text-base text-gray-500">
            Sorry, we couldn't find the page you're looking for
          </p>
          <div class="mt-6">
            <Link to="/" class="text-base font-medium text-emerald-400 hover:text-emerald-500">
              Go back home<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
